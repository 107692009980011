import React from 'react';
import './Playground.scss';

const Playground = () => {
	return (
		<div className={`Playground`}>
			<h1>H1 Header</h1>
			<h2>H2 Header</h2>
			<h3>H3 Header</h3>
			<h4>H4 Header</h4>
			<h5>H5 Header</h5>
			<p>p tag</p>
		</div>
	);
};

export default Playground;

export const autoInsuranceLink =
	'https://creditoneinsurance.insuranceaisle.com/vehicle/auto';
export const homeInsuranceLink =
	'https://creditoneinsurance.insuranceaisle.com/property/home';
export const condoInsuranceLink =
	'https://creditoneinsurance.insuranceaisle.com/common/condo';
export const rentersInsuranceLink =
	'https://creditoneinsurance.insuranceaisle.com/property/renters';
export const scheduleACallLink =
	'https://creditoneinsurance.insuranceaisle.com/scheduleacall';

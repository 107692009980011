import { useNavigate } from "react-router-dom";

export const useRouteTo = () => {
	const navigate = useNavigate();
	const routeTo = (to) => {
		const external = /^https:\/\//.test(to)
		if (external) {
			window.location.href = to;
		} else {
			navigate(to);
		}
	} 
	return routeTo;
}
import React from 'react';
import Home from '../pages/Home';
import Playground from '../pages/Playground';

const routes = [
	{
		path: '/',
		element: <Home />,
		index: true,
	},
	{
		path: '/playground',
		element: <Playground />,
	},
];

export default routes;

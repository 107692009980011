import React, { useState } from 'react';
import {
	Header,
	Section,
	IconCard,
	Row,
	Col,
	Button,
	Icon,
	Title,
	List,
	Footer,
	InputDropdown,
} from '@creditonebank/c1b-components';
// Helpers
import { heightEqualizer } from '../../helpers/heightEqualizer';
// Images
import HouseImg from '../../assets/images/graphics/insurance-house-vector.svg';
import AllstateLogo from '../../assets/images/logos/partners/allstate-color.svg';
import ProgressiveLogo from '../../assets/images/logos/partners/progressive-color.svg';
import LibertyLogo from '../../assets/images/logos/partners/liberty-mutual-color.svg';
import HippoLogo from '../../assets/images/logos/partners/hippo-color.svg';
import SafecoLogo from '../../assets/images/logos/partners/safeco-color.svg';
import AutoInsBadge from '../../assets/images/icons/badge/icon-badge-auto-insurance.svg';
import CondoInsBadge from '../../assets/images/icons/badge/icon-badge-condo-insurance.svg';
import HomeInsBadge from '../../assets/images/icons/badge/icon-badge-home-insurance.svg';
import RentersInsBadge from '../../assets/images/icons/badge/icon-badge-renters-insurance.svg';
import phoneIcon from '../../assets/images/icons/phone-icon.svg';
import insuranceLogo from '../../assets/images/logos/credit_one_insurance_logo.svg';

import './Home.scss';
import {
	autoInsuranceLink,
	condoInsuranceLink,
	homeInsuranceLink,
	rentersInsuranceLink,
	scheduleACallLink,
} from '../../helpers/vendorLinks';
import { useRouteTo } from '../../helpers/routeTo';

const Home = () => {
	const routeTo = useRouteTo();
	const [insuranceCoverage, setInsuranceCoverage] = useState('');
	heightEqualizer(['.shop-ins-desc']);
	const insuranceCustomMessage = (
		<div className="insurance_message">
			<div className="save-today text-center">
				<small className="M-0">Call today to start saving!</small>
				<h2 className="text-primary">888-444-0762</h2>
			</div>
			<a href="tel:18663440014">
				<Icon className="phone-icon" src={phoneIcon} size="badge" />
			</a>
		</div>
	);
	const startQuote = (e) => {
		e.preventDefault();
		switch (insuranceCoverage) {
			case 'Auto':
				routeTo(autoInsuranceLink);
				break;
			case 'Home':
				routeTo(homeInsuranceLink);
				break;
			case 'Condo':
				routeTo(condoInsuranceLink);
				break;
			case 'Renters':
				routeTo(rentersInsuranceLink);
				break;
			default:
				break;
		}
	};
	'Auto', 'Home', 'Condo', 'Renters';
	return (
		<div className="Home">
			<Header
				theme="light"
				logo={insuranceLogo}
				customMessage={insuranceCustomMessage}
				customMessageColumnSize={1}
			/>
			<Section bgColor="gradient" className="Home__hero PX-1">
				<h1 className="Home__hero__title text-center">
					No-cost, no-obligation quotes in minutes
				</h1>
				<p className="Home__hero__paragraph__1 text-center">
					As home values and car prices increase, so do insurance
					rates&mdash;now is a great time to shop
				</p>
				<p className="Home__hero__paragraph__2 MB-1 MB-MD-2 text-center">
					Select a product to get started
				</p>
				<Row md={4} align="center" className="Home__hero__cards">
					<a
						className="Home__hero__cards__card"
						href={autoInsuranceLink}
					>
						<IconCard
							src={AutoInsBadge}
							className="Home__IconText"
							chevron
							highlight
							breakpoint={1064}
						>
							<strong>Auto</strong>
						</IconCard>
					</a>
					<a
						className="Home__hero__cards__card"
						href={homeInsuranceLink}
					>
						<IconCard
							src={HomeInsBadge}
							className="Home__IconText"
							chevron
							highlight
							breakpoint={1064}
						>
							<strong>Home</strong>
						</IconCard>
					</a>
					<a
						className="Home__hero__cards__card"
						href={rentersInsuranceLink}
					>
						<IconCard
							src={RentersInsBadge}
							className="Home__IconText"
							chevron
							highlight
							breakpoint={1064}
						>
							<strong>Renters</strong>
						</IconCard>
					</a>
				</Row>
			</Section>
			<Section
				bgColor="light-grey"
				className="Home__partners text-center"
				containerClass="PT-SM-1 PT-MD-2"
			>
				<h2 className="h4 PX-1">
					Credit One Insurance works with over 40 top insurance
					companies, including but not limited to:
				</h2>
				<Row align="center" className="PT-2 Home__partners__logos">
					<Col xs={6} md>
						<img alt="Allstate logo" src={AllstateLogo} />
					</Col>
					<Col xs={6} md>
						<img alt="Progressive logo" src={ProgressiveLogo} />
					</Col>
					<Col xs={6} md>
						<img alt="Liberty Mutual logo" src={LibertyLogo} />
					</Col>
					<Col xs={6} md>
						<img alt="Hippo logo" src={HippoLogo} />
					</Col>
					<Col xs={6} md>
						<img alt="SafeCo logo" src={SafecoLogo} />
					</Col>
				</Row>
			</Section>
			<Section className="Home__coverage">
				<h2 className="MY-MD-2">
					Get the right coverage at the best price for you
				</h2>
				<Row align="center">
					<Col className="text-center" xs={6}>
						<img alt="House graphic" src={HouseImg} />
					</Col>
					<Col xs={6}>
						<p className="fs-20 MY-2">
							Talk to a licensed agent today. Call{' '}
							<a className="LINK" href="tel:1-888-444-0762">
								<span className="Home__nowrap">
									<strong>888-444-0762</strong>
								</span>
							</a>
							.
						</p>
						<List
							className="Home__coverage__list"
							items={[
								'Find the best rates for you',
								'Get discounts for bundling',
								'and more!',
							]}
						/>
						<h3 className="fs-18 MB-0">Can&apos;t talk now?</h3>
						<p className="fs-18">
							<a className="LINK" href={scheduleACallLink}>
								Schedule a call
							</a>
							&nbsp;and we&apos;ll reach out to you when
							you&apos;re ready.
						</p>
					</Col>
				</Row>
			</Section>
			<Section className="Home__products">
				<h2 className="MB-2 MT-MD-1">Shop insurance products</h2>
				<Row>
					<Col xs={6}>
						<div className="Home__products__icon_container">
							<img src={AutoInsBadge} alt="Auto Insurance Icon" />
						</div>
						<Title tag="h3">Auto Insurance</Title>
						<p className="PR-MD-3">
							Whether you&apos;re looking for just the basics, or
							comprehensive coverage, we offer a range of carriers
							and coverage to meet your unique insurance needs.
						</p>
						<Button
							icon="chevron"
							size="medium"
							variant="link"
							href={autoInsuranceLink}
						>
							Start Your Quote
						</Button>
					</Col>
					<Col xs={6}>
						<div className="Home__products__icon_container">
							<img src={HomeInsBadge} alt="Home Insurance Icon" />
						</div>
						<Title tag="h3">Home Insurance</Title>
						<p className="PR-MD-3">
							Shopping for insurance isn&apos;t always about
							finding the lowest price, it&apos;s about finding
							the right coverage too. Start a quote today to see
							how we can help!
						</p>
						<Button
							icon="chevron"
							size="medium"
							variant="link"
							href={homeInsuranceLink}
						>
							Start Your Quote
						</Button>
					</Col>
				</Row>
				<Row>
					<Col xs={6}>
						<div className="Home__products__icon_container">
							<img
								src={CondoInsBadge}
								alt="Condo Insurance Icon"
							/>
						</div>
						<Title tag="h3">Condo Insurance</Title>
						<p className="PR-MD-3">
							You may not have to mow the lawn, but it&apos;s
							still important to make sure your condo is covered!
						</p>
						<Button
							icon="chevron"
							size="medium"
							variant="link"
							href={condoInsuranceLink}
						>
							Start Your Quote
						</Button>
					</Col>
					<Col xs={6}>
						<div className="Home__products__icon_container">
							<img
								src={RentersInsBadge}
								alt="Renters Insurance Icon"
							/>
						</div>
						<Title tag="h3">Renters Insurance</Title>
						<p className="PR-MD-3">
							Protecting your belongings is easy with the help of
							Credit One Insurance Agency, LLC
						</p>
						<Button
							icon="chevron"
							size="medium"
							variant="link"
							href={rentersInsuranceLink}
						>
							Start Your Quote
						</Button>
					</Col>
				</Row>
			</Section>
			<Section bgColor="light-grey" className=" Home__quote">
				<Title tag="h3" align="center" className="MB-2">
					Start a quote today
				</Title>
				<Row>
					<Col xs={6} md={5}>
						<InputDropdown
							id="dd"
							label="What do you want to cover?"
							name="dropdown-input"
							options={['Auto', 'Home', 'Condo', 'Renters']}
							setValue={setInsuranceCoverage}
							type="dropdown"
							value={insuranceCoverage}
						/>
					</Col>
					<Col xs="auto">
						<Button
							className="MB-H"
							icon="chevron"
							onClick={(e) => startQuote(e)}
						>
							Start Your Quote
						</Button>
					</Col>
				</Row>
				<hr className="Home__hr" />
				<Row>
					<Col className="text-center">
						<Title tag="h3" align="center" className="MB-H">
							Or speak with a licensed agent
						</Title>
						<a className="h2 LINK Home__phone">888-444-0762</a>
					</Col>
				</Row>
			</Section>
			<Section className="footer_disclaimer">
				<p>
					Credit One Insurance Agency, LLC is a limited liability
					company organized under Nevada law and is licensed as an
					insurance agency in Nevada and in all other states where our
					services are available.
				</p>
				<p>
					Insurance products are underwritten and serviced
					independently of Credit One Insurance Agency, LLC.
					Information on this page is not a contract or binder of
					coverage. Not all carriers are available in all states.
				</p>
				<p>
					Any insurance required as a condition of the extension of
					credit by Credit One Bank, N.A. or any affiliate need not be
					purchased from Credit One Insurance Agency, LLC, but may,
					without affecting the approval of the application for an
					extension of credit, be purchased from an agent or insurance
					company of the customer&apos;s choice.
				</p>
				<p>
					Insurance products are not insured by the Federal Deposit
					Insurance Corporation (FDIC) or any other agency of the
					United States, Credit One Bank, N.A. or any affiliate, and
					are not a deposit or other obligation of, or guaranteed by,
					Credit One Bank, N.A. or any affiliate.
				</p>
			</Section>
			<Footer
				copyright="© 2024 Credit One Insurance Agency, LLC. | All rights reserved. | "
				utilityLinks={[
					{
						label: 'Agency Licenses',
						link: 'https://creditoneinsurance.com/assets/pdf/coi_licenses.pdf',
					},
					{
						label: 'Terms of Use',
						link: 'https://creditoneinsurance.com/legal/terms-of-use/',
					},
					{
						label: 'Privacy Policy',
						link: 'https://creditoneinsurance.com/legal/privacy-policy/',
					},
				]}
			/>
		</div>
	);
};

export default Home;

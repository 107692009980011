import { useEffect } from "react";
export const setHeightWithSelector = (selector) => {
	const gridSlideTitle = [...document.querySelectorAll(selector)];
	gridSlideTitle.forEach((x) => {
		x.style.height = '';
	});
	if (gridSlideTitle.length) {
		const gridSlideHt = gridSlideTitle.reduce((a, c, i) => {
			a[i] = c.offsetHeight;
			return a;
		}, []);
		gridSlideTitle.forEach((x) => {
			x.style.height = `${Math.max(...gridSlideHt)}px`;
		});
	}
};

export const heightEqualizer = (arrayOfSelectors) => {
	arrayOfSelectors.forEach((selector) => {
		useEffect(() => {
			setHeightWithSelector(selector);

			window.addEventListener('resize', () => {
				setHeightWithSelector(selector);
			});

			window.addEventListener('DOMContentLoaded', () => {
				setHeightWithSelector(selector);
			});
		}, []);
	});
};